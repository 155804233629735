import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Button,
  FormControl,
  InputAdornment,
  Paper,
  Autocomplete,
} from "@mui/material";
import {
  getResiduoById,
  getResiduos,
  getResiduoByIdAndAlmacen,
} from "../service/apiResiduos";
import Modal from "../modal/modal";

function ResiduosTable({ almacenId, rowsP, onRowsUpdate }) {
  const [residuos, setResiduos] = useState([]);
  const [rows, setRows] = useState([
    {
      id: 1,
      articulo: "",
      descripcion: "",
      cantidad: 0,
      precio: 0,
      subtotal: 0,
      impuesto: 0,
      total: 0,
      residuos: [],
    },
  ]);
  const [residuosOptions, setResiduosOptions] = useState([]);
  const [isReadOnly, setIsReadOnly] = useState(true);
  const [editableRow, setEditableRow] = React.useState(null);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  useEffect(() => {
    const fetchResiduosForAlmacen = async () => {
      if (!almacenId) {
        //alert("Debe seleccionar un almacén antes de continuar.");
        return;
      }

      const updatedRows = [...rows];
      for (let index = 0; index < updatedRows.length; index++) {
        const currentRow = updatedRows[index];

        // Si el campo "articulo" está vacío, resetea la fila
        if (!currentRow.articulo) {
          updatedRows[index] = {
            ...currentRow,
            articulo: "",
            descripcion: "",
            precio: 0,
            impuesto: 0,
            cantidad: 0,
            subtotal: 0,
            total: 0,
          };
        } else {
          //console.log('aqui entra cuando cambia almacen con un codigo de articulo')
          try {
            const [residuoData] = await getResiduoByIdAndAlmacen(
              almacenId.id,
              currentRow.articulo
            );
            if (residuoData) {
              updatedRows[index] = {
                ...currentRow,
                precio: residuoData.precio || 0,
                impuesto: residuoData.res_iva || 0,
                subtotal: currentRow.cantidad * residuoData.precio,
                total: currentRow.cantidad * residuoData.precio,
              };
            } else {
              // Resetea la fila si no hay información del residuo en el almacén
              updatedRows[index] = {
                ...currentRow,
                precio: 0,
                impuesto: 0,
                subtotal: 0,
                total: 0,
              };
              alert(
                `No se encontró precio para el artículo ${currentRow.articulo} en el almacén seleccionado.`
              );
            }
          } catch (error) {
            console.error(
              `Error al obtener residuo ${currentRow.articulo}:`,
              error
            );
            alert("Ocurrió un error al actualizar los datos de los residuos.");
          }
        }
      }

      setRows(updatedRows);
    };

    fetchResiduosForAlmacen();
  }, [almacenId]);

  const handleInputChange = async (index, field, value) => {
    const updatedRows = [...rows];
    updatedRows[index][field] = value;

    if (field === "articulo" && value) {
      if (!almacenId) {
        setShowModal(true);
        setModalMessage(
          `Debe Seleccionar un almacen para visualizar residuos`
        );
        updatedRows[index] = {
          articulo: "",
          descripcion: "",
          precio: 0,
          impuesto: 0,
          cantidad: 0,
          subtotal: 0,
          total: 0,
        };
        return;
      } else {
        const [residuoData] = await getResiduoByIdAndAlmacen(
          almacenId.id,
          value
        );
        if (residuoData) {
          updatedRows[index] = {
            ...updatedRows[index],
            articulo: residuoData.res_id || value,
            descripcion: residuoData.res_descripcion,
            precio: residuoData.precio || 0,
            impuesto: residuoData.res_iva || 0,
            cantidad: 0,
            subtotal: 0,
            total: 0,
          };
        } else {
          updatedRows[index] = {
            ...updatedRows[index],
            articulo: "",
            descripcion: "",
            precio: 0,
            impuesto: 0,
            cantidad: 0,
            subtotal: 0,
            total: 0,
          };
          setShowModal(true);
          setModalMessage("No hay arcitulo con el id", value);
        }
      }
    } else if (field === "articulo" && !value) {
      updatedRows[index] = {
        ...updatedRows[index],
        articulo: "",
        descripcion: "",
        precio: 0,
        impuesto: 0,
        cantidad: 0,
        subtotal: 0,
        total: 0,
      };
    } else if (field === "descripcion" && value) {
      if (!almacenId) {
        setShowModal(true);
        setModalMessage(
          `Debe Seleccionar un almacen para visualizar residuos`
        );
        
        return;
      }
      const residuoData = await getResiduos();
      const filteredResiduos = residuoData.filter((residuo) =>
        residuo.res_descripcion.toLowerCase().includes(value.toLowerCase())
      );
      const IDdescripResiduos = filteredResiduos[0]?.res_id || [];
      const residuoDataXPrecio = await getResiduoByIdAndAlmacen(
        almacenId.id,
        IDdescripResiduos
      );

      if (filteredResiduos.length === 1 && residuoDataXPrecio.length === 1) {
        const selectedResiduo = residuoDataXPrecio[0];

        updatedRows[index] = {
          ...updatedRows[index],
          articulo: selectedResiduo.res_id || "",
          descripcion: selectedResiduo.res_descripcion || "",
          precio: selectedResiduo.precio || 0,
          impuesto: selectedResiduo.res_iva || 0,
          cantidad: 0,
          subtotal: 0,
          total: 0,
        };
      }
    } else if (field === "descripcion" && !value) {
      updatedRows[index] = {
        ...updatedRows[index],
        articulo: "",
        descripcion: "",
        precio: 0,
        impuesto: 0,
        cantidad: 0,
        subtotal: 0,
        total: 0,
      };
    } else if (field === "cantidad" && value) {
      if (updatedRows) {
        const impuestoIVADesc =
          parseFloat(value) *
          parseFloat(updatedRows[index].precio) *
          (parseFloat(updatedRows[index].impuesto) / 100);
        const subtotal =
          parseFloat(value) * parseFloat(updatedRows[index].precio);
        const total =
          parseFloat(value) * parseFloat(updatedRows[index].precio) +
          impuestoIVADesc;

        //Actualizacion de totales
        updatedRows[index].subtotal = subtotal;
        updatedRows[index].total = total;
      } else {
        //Cuando no hay valor en updateRows
      }
    } else if (field === "cantidad" && !value) {
      updatedRows[index] = {
        ...updatedRows[index],
        cantidad: 0,
        total: 0,
      };
      // } else if (field === 'descuento' && value){

      //   if(updatedRows){

      //     const descuento = ((parseFloat(updatedRows[index].cantidad) * parseFloat(updatedRows[index].precio)) * (value / 100));
      //     const impuestoIVADesc = ((parseFloat(updatedRows[index].cantidad) * parseFloat(updatedRows[index].precio)) * (parseFloat(updatedRows[index].impuesto) / 100));
      //     const total = (((parseFloat(updatedRows[index].cantidad) * parseFloat(updatedRows[index].precio)) + impuestoIVADesc) - descuento )
      //     const subtotal = (parseFloat(updatedRows[index].cantidad) * parseFloat(updatedRows[index].precio))

      //     updatedRows[index].subtotal = subtotal;
      //     updatedRows[index].total = total

      //   }else {
      //     //Cuando no hay valor en updateRows
      //   }

      // } else if (field === 'descuento' && !value){
      //     const descuento = ((parseFloat(updatedRows[index].cantidad) * parseFloat(updatedRows[index].precio)) * (value || 0 / 100));
      //     const impuestoIVADesc = ((parseFloat(updatedRows[index].cantidad) * parseFloat(updatedRows[index].precio)) * (parseFloat(updatedRows[index].impuesto) / 100));
      //     const total = ((parseFloat(updatedRows[index].cantidad) * parseFloat(updatedRows[index].precio)) - (descuento-impuestoIVADesc) )
      //     const subtotal = (parseFloat(updatedRows[index].cantidad) * parseFloat(updatedRows[index].precio))

      //     updatedRows[index] = {
      //       ...updatedRows[index],
      //       descuento: 0,
      //       subtotal: subtotal,
      //       total:total,

      //     };
      // } else if (field === 'precio' && value) {
      //setIsReadOnly(true);
    } else if (field === "precio" && value) {
      const valueParse = parseFloat(value);
      const impuestoIVADesc =
        parseFloat(value) *
        parseFloat(updatedRows[index].cantidad) *
        (parseFloat(updatedRows[index].impuesto) / 100);
      const subtotal =
        parseFloat(value) * parseFloat(updatedRows[index].cantidad);
      const total =
        parseFloat(value) * parseFloat(updatedRows[index].cantidad) +
        impuestoIVADesc;

      //Actualizacion de totales
      updatedRows[index] = {
        ...updatedRows[index],
        precio: valueParse,
        total: total,
        subtotal: subtotal,
      };
    } else if (field === "precio" && !value) {
      updatedRows[index] = {
        ...updatedRows[index],
        precio: 0,
        total: 0,
      };
    } else {
      updatedRows[index].total = calculateTotal(updatedRows[index]);
    }
    onRowsUpdate(updatedRows);
    setRows(updatedRows);
  };

  const calculateTotal = (row) => {
    const subtotal = row.cantidad * row.precio;
    const discountAmount = (row.descuento / 100) * subtotal;
    return subtotal - discountAmount;
  };

  const handleAddRow = () => {
    setRows([
      ...rows,
      {
        id: rows.length + 1,
        articulo: "",
        descripcion: "",
        cantidad: 0,
        precio: 0,
        subtotal: 0,
        descuento: 0,
        impuesto: 0,
        total: 0,
        residuos: [],
      },
    ]);
  };

  const handleDeleteRow = (index) => {
    const updatedRows = rows.filter((_, i) => i !== index);
    onRowsUpdate(updatedRows);
    //console.log("updatedRows", updatedRows);
    setRows(updatedRows);
  };

  //Habilita el campo de precio para editarlo
  const handleDoubleClick = (index) => {
    if (editableRow !== index) {
      setEditableRow(index); // Establece la fila que será editable
      setShowModal(true);
      setModalMessage(
        `El campo de la fila ${index + 1} se habilitará para ser editado.`
      );
    } else {
      setEditableRow(null); // Desactiva la edición si vuelves a hacer doble clic
    }
  };
  // ///deshabilita el campo de precio cuando sale del foco
  // const handleBlur = () => {
  //   setIsReadOnly(true); // Deshabilitar el campo al perder el foco
  // };

  const handleCloseModal = () => {
    setShowModal(false);
    setModalMessage("");
  };

  return (
    <div style={{ padding: "10px" }}>
      <h3 className="form-subtitle">Agregar Residuos</h3>
      <TableContainer component={Paper} style={{ overflowX: "auto" }}>
        <Table style={{ minWidth: "700px" }}>
          <TableHead className="tableHead-residuos">
            <TableRow>
              <TableCell>Linea</TableCell>
              <TableCell>Artículo</TableCell>
              <TableCell>Descripción</TableCell>
              <TableCell>Cantidad</TableCell>
              <TableCell>Precio</TableCell>
              <TableCell>Impuesto</TableCell>
              <TableCell>Total</TableCell>
              <TableCell>Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow key={row.id}>
                <TableCell width={"5%"}>{index + 1}</TableCell>
                <TableCell width={"15%"}>
                  <FormControl fullWidth>
                    <TextField
                      value={row.articulo}
                      onChange={(e) =>
                        handleInputChange(index, "articulo", e.target.value)
                      }
                      placeholder="Artículo (ID)"
                      variant="outlined"
                    />
                  </FormControl>
                </TableCell>
                <TableCell width={"20%"}>
                  <FormControl fullWidth>
                    <Autocomplete
                      value={{
                        res_descripcion: rows[index]?.descripcion || "",
                      }}
                      options={residuosOptions}
                      getOptionLabel={(option) => option.res_descripcion || ""}
                      onInputChange={(event, newValue) =>
                        handleInputChange(index, "descripcion", newValue)
                      }
                      onChange={(event, value) => {
                        if (value) {
                          handleInputChange(
                            index,
                            "descripcion",
                            value.res_descripcion
                          );
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          onChange={(e) =>
                            handleInputChange(
                              index,
                              "descripcion",
                              e.target.value
                            )
                          }
                          placeholder="Descripción"
                          variant="outlined"
                        />
                      )}
                    />
                  </FormControl>
                </TableCell>
                <TableCell width={"10%"}>
                  <FormControl fullWidth>
                    <TextField
                      type="text"
                      value={row.cantidad}
                      onChange={(e) =>
                        handleInputChange(
                          index,
                          "cantidad",
                          parseFloat(e.target.value)
                        )
                      }
                      placeholder="Cantidad"
                      variant="outlined"
                    />
                  </FormControl>
                </TableCell>
                <TableCell width={"10%"}>
                  <FormControl fullWidth>
                    <TextField
                      title="Doble clic para editar el precio" 
                      type="text"
                      value={row.precio}
                      onChange={(e) =>
                        handleInputChange(
                          index,
                          "precio",
                          parseFloat(e.target.value)
                        )
                      }
                      placeholder="Precio"
                      variant="outlined"
                      onDoubleClick={() => handleDoubleClick(index)} // Pasa el índice de la fila
                      InputProps={{
                        readOnly: editableRow !== index, // Solo editable si es la fila activa
                      }}
                    />
                    {showModal && (
                      <Modal
                        message={modalMessage}
                        onClose={() => setShowModal(false)}
                      />
                    )}
                  </FormControl>
                </TableCell>
                {/* <TableCell width={'10%'}>
                  <FormControl fullWidth>
                    <TextField
                      type="text"
                      value={row.descuento}
                      onChange={(e) => handleInputChange(index, 'descuento', parseFloat(e.target.value))}
                      placeholder="Descuento"
                      variant="outlined"
                    />
                  </FormControl>
                </TableCell> */}
                <TableCell width={"10%"}>
                  <FormControl fullWidth>
                    <TextField
                      type="text"
                      value={row.impuesto || 0}
                      onChange={(e) =>
                        handleInputChange(
                          index,
                          "impuesto",
                          parseFloat(e.target.value)
                        )
                      }
                      placeholder="Impuesto"
                      variant="outlined"
                      disabled={true}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                </TableCell>
                <TableCell width={"10%"}>{row.total.toFixed(2)}</TableCell>
                <TableCell width={"10%"}>
                  <Button
                    color="error"
                    variant="outlined"
                    onClick={() => handleDeleteRow(index)}
                  >
                    Eliminar
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Button
          style={{ background: "#1abd9c", marginTop: "10px" }}
          onClick={handleAddRow}
          variant="contained"
        >
          Agregar Fila
        </Button>
      </TableContainer>
    </div>
  );
}

export { ResiduosTable };
