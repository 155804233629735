import axios from 'axios';
import API_URL from  './config';

export const getProveedores = async () => {

    try{
        const response = await axios.get(`${API_URL}/proveedor/getProveedores`);
  
        return response.data

    } catch(error){
        console.error('Error obteniendo proveedores', error );
        return [];
    }
}



