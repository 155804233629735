import axios from 'axios';
import API_URL from  './config';

export const getEstadoAlbaran = async () => {

    try{
        const response = await axios.get(`${API_URL}/estadoAlbaran/getEstadoAlbaran`);        
        return response.data

    } catch(error){
        console.error('Error obteniendo proveedores', error );
        return [];
    }
}