import React, { useState, useEffect } from 'react';
import { Select, CircularProgress, FormControl, InputLabel, MenuItem } from '@mui/material';
import { getAlmacenes } from '../service/apiAlmacenes';

const AlmacenesSelect = ({ onAlmacenChange }) => {
    const [almacenes, setAlmacenes] = useState([]);
    const [selectedAlmacen, setSelectedAlmacen] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    useEffect(() => {
        const fetchAlmacenes = async () => {
            setLoading(true);
            try {
                const response = await getAlmacenes();
                setAlmacenes(response);
                setError(false);
            } catch (error) {
                console.error('Error al obtener los almacenes', error);
                setError(true);
            }
            setLoading(false);
        };
        fetchAlmacenes();
    }, []);

    const handleChange = (event) => {
        const almacenId = event.target.value;
        const almacen = almacenes.find((alm) => alm.alm_id === almacenId);
        const alm_name = almacen ? almacen.alm_nombre : '';
        setSelectedAlmacen(almacenId);
        onAlmacenChange(almacenId, alm_name);
    };

    return (
        <FormControl fullWidth>
            <InputLabel id="almacen-label">Selecciona Almacén</InputLabel>
            {error ? (
                <p style={{ color: 'red' }}>Error al cargar los almacenes. Inténtalo de nuevo.</p>
            ) : loading ? (
                <CircularProgress />
            ) : (
                <Select
                    labelId="almacen-label"
                    value={selectedAlmacen}
                    label="Selecciona Almacén"
                    onChange={handleChange}
                    disabled={loading || error}
                    MenuProps={{
                        PaperProps: {
                            style: {
                                maxHeight: 150,
                                overflowY: 'auto',
                            },
                        },
                    }}
                >
                    {almacenes.map((almacen) => (
                        <MenuItem key={almacen.alm_id} value={almacen.alm_id}>
                            {almacen.alm_nombre}
                        </MenuItem>
                    ))}
                </Select>
            )}
        </FormControl>
    );
};

export { AlmacenesSelect };
