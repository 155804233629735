import React, { useState } from 'react';
import { ItemsForm } from './form/form'; 
import {ResiduosList} from './components/residuosList';
import {ProveedoresSelect} from  './components/proveedorSelect';


//import logo from '../public/logo192.png'; // Asegúrate de tener el logo en la carpeta adecuada
import './App.css';

function App() {

    return(
        <>
        <ItemsForm/>

        </>

    )}

export default App;
