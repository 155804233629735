import axios from 'axios';
import API_URL from  './config';

export const getAlmacenes = async () => {

    try{
        const response = await axios.get(`${API_URL}/almacen/getAlmacenes`);
  
        return response.data

    } catch(error){
        console.error('Error obteniendo errores rtekahsdljagsd ', error );
        return [];
    }
}

