import React, { useState, useEffect } from 'react';
import { TextField, CircularProgress, Autocomplete } from '@mui/material';
import { getProveedores } from '../service/apiProveedores';

const ProveedoresSelect = ({onProveedorChange, valorIdCliente, nifCliente, numTarjeta, numCuenta, matricula}) => {
  const [proveedores, setProveedores] = useState([]); // Lista de proveedores
  const [filteredProveedores, setFilteredProveedores] = useState([]); // Lista filtrada
  const [selectedProveedor, setSelectedProveedor] = useState(null); // Proveedor seleccionado
  const [loading, setLoading] = useState(false); // Estado de carga
  const [searchQuery, setSearchQuery] = useState(''); // Para el campo de búsqueda

  // Función para obtener proveedores del backend
  const fetchProveedores = async () => {
    try {
      setLoading(true);
      const response = await getProveedores();
      setProveedores(response); 
      setFilteredProveedores(response); // Inicialmente, mostrar todos los proveedores
      setLoading(false);
    } catch (error) {
      console.error('Error al obtener los proveedores:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProveedores();
  }, []);

  

// Efecto para filtrar proveedores cuando searchQuery cambia
  useEffect(() => {
    const searchTrimmed = searchQuery.trim().toLowerCase(); // Normalizamos la búsqueda en minúsculas

    const result = proveedores.filter((proveedor) => {
      const nifProveedor = proveedor.prov_nif.toString().toLowerCase(); // NIF en minúsculas
      const nombreProveedor = proveedor.prov_nombre.toLowerCase(); // Nombre en minúsculas

      // Verificar si la búsqueda está en el NIF o en el nombre
      const coincideNif = nifProveedor.includes(searchTrimmed);
      const coincideNombre = nombreProveedor.includes(searchTrimmed);

      // Retornar verdadero si coincide en nombre o en NIF
      return coincideNif || coincideNombre;
    });

    setFilteredProveedores(result); // Actualizar los proveedores filtrados
  }, [searchQuery, proveedores]);

// Maneja la selección de un proveedor
const handleProveedorChange = (event, newValue) => {
    setSelectedProveedor(newValue);
    //console.log('newValue', newValue)
    if (typeof onProveedorChange === 'function') 
      {
        if(newValue){
          const nombreProveedor = newValue.prov_nombre
          onProveedorChange(nombreProveedor);
          valorIdCliente(newValue.prov_id);
          nifCliente(newValue.prov_nif);
          numTarjeta(newValue.prov_num_tarjeta_ecopay);
          numCuenta(newValue.prov_cta_bancaria);
          matricula(newValue.prov_matricula)
        }else {
          valorIdCliente('')
          matricula('')
        }
      }


};

// Maneja los cambios en el input de búsqueda
const handleSearchQueryChange = (event, newInputValue) => {
    setSearchQuery(newInputValue);
    // Filtra proveedores según el texto ingresado
    setFilteredProveedores(
        proveedores.filter((proveedor) =>
            proveedor.prov_nombre.toLowerCase().includes(newInputValue.toLowerCase()) ||
            proveedor.prov_nif.toLowerCase().includes(newInputValue.toLowerCase())
        )
    );
};

  return (
    <div>
      {loading ? (
        <CircularProgress />
      ) : (
        <Autocomplete
          options={filteredProveedores} // Usar proveedores filtrados
          getOptionLabel={(option) => `${option.prov_nombre} - ${option.prov_nif}`} // Mostrar nombre y NIF
          value={selectedProveedor}
          onChange={handleProveedorChange} // Llamar a handleProveedorChange en cambios de selección
          inputValue={searchQuery} // Vincular el valor del input
          onInputChange={handleSearchQueryChange} // Llamar a handleSearchQueryChange en cambios de input
          noOptionsText="No se encontraron proveedores"
          renderInput={(params) => (
              <TextField
                  {...params}
                  label="Selecciona un proveedor"
                  variant="outlined"
                  fullWidth
              />
          )}
        />
      )}
    </div>
  );
};

export { ProveedoresSelect };
